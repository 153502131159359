/**
 * Utilities for working with maps
 */

import moment, { Moment } from "moment";
import { ICollection, ITheme } from "../types/ICollection";
import { IFilter, IMap, IMapLegend } from "../types/IMap";
import { getService } from "react-in-angularjs";
import { findTheme } from "./collection";

export type PreflightCollection = {
  ID: number;
  name: string;
  tableName: string;
  theme: ITheme;
  filters: IFilter[];
};

export type PreflightMap = {
  entityID: number;
  selectable: { [key: string]: boolean };
  collections: PreflightCollection[];
  minDate: string | null;
  maxDate: string | null;
  compInterval: string | null;
  UUID: string | null;
  password: string | null;
};

export const getLegends = (map: IMap, collections: ICollection[]) => {
  const legends: IMapLegend[] = [];
  collections.forEach((collection) => {
    const theme = findTheme(collection, map);
    if (!theme) return;

    if (!theme.legend.length || !map.visible[collection.ID]) return;

    const entry = { entries: theme.legend, collectionID: collection.ID, theme, name: "" };
    if (!theme.customLegendHeader || theme.showLegendHeader) entry.name = collection.name;

    if (theme.customLegendHeader) entry.name = theme.customLegendHeader;

    legends.push(entry);
  });

  legends.forEach(function (legend) {
    if (!legend.theme?.showLegendHeader && legends.length <= 1) delete legend.name;

    delete legend.theme;
  });

  return legends;
};

export const createMaxDateRange = (map: IMap, collections: ICollection[]) => {
  // Create the objects to hold the date range
  // maxDate range is the object that holds the max (and min) values of the map's data,
  const maxDateRange: { minDate?: Moment; maxDate?: Moment } = {};

  // Loop through all the collections, finding the minimum date range and the maximum date range
  for (let i = 0; i < collections.length; i++) {
    const collection = collections[i]!;

    if (!map.visible[collection.ID] || !collection.dateRange) continue;

    const min = moment(collection.dateRange[0]),
      max = moment(collection.dateRange[1]);

    // If the date is undefined or before the minDate, set it
    if (typeof maxDateRange.minDate == "undefined" || min.isBefore(maxDateRange.minDate)) maxDateRange.minDate = min;

    // If the date is undefined or after the maxDate, set it
    if (typeof maxDateRange.maxDate == "undefined" || max.isAfter(maxDateRange.maxDate)) maxDateRange.maxDate = max;
  }

  // If the dates aren't set, make it a range that spans the last three years
  if (!maxDateRange.maxDate) maxDateRange.maxDate = moment();

  if (!maxDateRange.minDate) maxDateRange.minDate = moment().subtract(3, "year");

  return maxDateRange;
};

export const createDateRange = (map: IMap) => {
  const dateRange: {
    minDate?: Moment;
    maxDate?: Moment;
  } = {};

  if (map.sliderType == 1) {
    const interval = getService("Interval").getNoun(map.sliderInterval);
    dateRange.minDate = map.maxDateRange?.minDate?.clone().startOf(interval);
    dateRange.maxDate = map.maxDateRange?.minDate?.clone().endOf(interval);
  }

  return dateRange;
};

export function getPreflightMap(map: IMap, availableCollections: ICollection[]): PreflightMap {
  const collections = map.collectionIDs
    .map((id) => availableCollections.find((c) => c.ID == id))
    .filter((c): c is ICollection => !!c?.ID && map.visible[c.ID])
    .map((c) => getPreflightCollection(c, map));

  return {
    entityID: map.entityID,
    selectable: map.selectable,
    // Order must be flipped because layers are painted as if the first is the bottom of the stack.
    collections: [...collections].reverse(),
    minDate: map.dateRange?.minDate ? map.dateRange.minDate.format("YYYY-MM-DD HH:mm:ss") : null,
    maxDate: map.dateRange?.maxDate ? map.dateRange.maxDate.format("YYYY-MM-DD HH:mm:ss") : null,
    compInterval: map.compInterval || null,
    UUID: map.isPublic ? map.UUID : "",
    password: map.isPublic ? map.password : null,
  };
}

function getPreflightCollection(collection: ICollection, map: IMap): PreflightCollection {
  return {
    ID: collection.ID,
    name: collection.name,
    tableName: collection.tableName,
    theme: findTheme(collection, map),
    filters: map.filters[collection.ID] || [],
  };
}

export const getEmptyMap = function (workspaceID: number, entityID: number): IMap {
  return {
    ID: 0,
    name: "Untitled Map",
    entityID: entityID,
    workspaceID: workspaceID,
    UUID: "",
    password: null,
    passwordRequired: false,
    dateEnabled: false,
    compEnabled: false,
    createdAt: null,
    updatedAt: null,
    sorder: 0,
    collectionIDs: [],
    visible: {},
    selectable: {},
    editable: {},
    themes: {},
    labels: {},
    filters: {},
    dateRange: {},
    sliderType: 1,
    sliderInterval: 1,
    reportsEnabled: false,
    reportsCollectionID: null,
    reports: [],
    allowCollaboration: false,
  };
};

export function getCollections(availableCollections: ICollection[], map?: IMap): ICollection[] {
  return map?.collectionIDs
      .map(id => availableCollections.find(c => c.ID == id))
      .filter((c): c is ICollection => !!c)
    || [];
}

export function oxfordConcat(vals: string[], addQuotes: boolean = false, conjunction: string = "and"): string {
  if (addQuotes) {
    vals = vals.map(s => `"${s}"`);
  }

  if (vals.length == 0) {
    return "";
  }
  if (vals.length == 1) {
    return vals[0];
  }
  if (vals.length == 2) {
    return vals.join(" and ");
  }

  // Prefix last element with "and" or "or", then join all with commas.
  const withAnd = [...vals.slice(0,-1), conjunction + " " + vals[vals.length-1]];
  return withAnd.join(", ");
}

export const intervals = ["1 month", "3 months", "6 months", "1 year", "2 years", "5 years"];
Object.freeze(intervals);
