export interface IReport {
  ID?: number;
  mapID: number;
  templateID: string;
  name: string;
  interactionScope: string[];
  collectionScope: number[];
  areaType: ReportAreaType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: { [key: string]: any };
  createdAt: string;
  updatedAt?: string;
}

export type AvailableReport = {
  ID: number;
  name: string;
  type: "old" | "new";
  collectionScope: number[];
  areaType: ReportAreaType;
  enabled?: boolean;
  acceptsSelection?: boolean;
  mustDisplay?: boolean;
};

export enum ReportAreaType {
  Point = "point",
  Circle = "circle",
  Isochrone = "isochrone",
  Feature = "feature",
  MultiFeature = "multi-feature",
}