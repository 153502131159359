import React, { useMemo, useState } from "react";
import { JsonForms, TranslateProps, useJsonForms } from "@jsonforms/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { withReduxProvider } from "../../services/withReduxProvider";
import {
  useCreateReportMutation,
  useUpdateReportMutation,
  useGetDataSourcesQuery,
  useGetReportTemplatesQuery,
  useGetReportsQuery,
  useDeleteReportMutation,
} from "../../slices/apiSlice";
import { MaterialOneOfEnumControl, materialCells, materialRenderers } from "@jsonforms/material-renderers";
import {
  ControlElement,
  ControlProps,
  EnumOption,
  JsonSchema7,
  Layout,
  OwnPropsOfEnum,
  RankedTester,
  rankWith,
  resolveSchema,
  schemaMatches,
} from "@jsonforms/core";
import { WithOptionLabel } from "@jsonforms/material-renderers";
import { Modal } from "../../components/Modal";
import { Field, Formik, FormikHelpers } from "formik";
import { Button } from "../../design/Button";
import { ICollection } from "../../types/ICollection";
import { classNameMapper } from "../../utils/classNameMapper";
import { useAppSelector } from "../../hooks/useAppSelector";
import { ITemplateVariable } from "../../types/IReportTemplate";
import { InputLabel } from "@mui/material";
import { IDataSource } from "../../types/IDataSource";
import { IReport, ReportAreaType } from "../../types/IReport";
import { getCollections, oxfordConcat } from "../../data/map";
import { skipToken } from "@reduxjs/toolkit/query";
import { ConfirmButton } from "../../components/ConfirmButton";

/**
 * When a collection property is required (like ID or tableName, or one of its data columns), the
 * JSON Schema for datasources requires a simple integer, string, or array. JSONForms would naturally
 * render the field(s) as a number or text input, but we really want a <select> with a list of collections
 * (or list of column names, etc.).
 *
 * Here we define:
 *  1. An extension to JSON Schema allowing the backend datasource to choose a frontend renderer and define
 *     arguments for it,
 *  2. A new component for each _renderer value that renders an appropriate select/dropdown,
 *  3. A tester for each component that tells JSONForms to choose a component based on _renderer.
 */
interface ExtendedSchema extends JsonSchema7 {
  _renderer?: "Collection" | "CollectionColumn";
  _rendererArgs?: {
    location?: "map" | "entity";
    property?: string;
    filter?: ExtendedSchemaFilter;
  };
}

type ExtendedSchemaFilter = {
  field: string;
  operator: "notEmpty";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function notEmpty(val: any): boolean {
  if (!val) {
    return false;
  }

  if (Array.isArray(val)) {
    return val.length > 0;
  }

  return Object.keys(val).length > 0;
}

/**
 * A component to select a property from a collection, e.g., ID or tableName.
 *
 * _rendererArgs supports:
 *  - location: where to get the list of collections (e.g., only on the map, or the whole entity)
 *  - property: what property of the collection should be the value of each <option> (the collection name will always be the label).
 *  - filter: a way further restrict the list of collection, e.g., to those which have an aggregate query.
 */
const CollectionSelectorControl = (props: ControlProps & OwnPropsOfEnum & WithOptionLabel & TranslateProps) => {
  const schema = resolveSchema(props.schema, props.uischema.scope, props.schema) as ExtendedSchema;
  const { map, availableCollections } = useAppSelector((state) => state.pages.truterritory);

  // _rendererArgs.location tells us where to find collections, either "map" or "entity" (default).
  const collections =
    schema._rendererArgs?.location == "map" ? getCollections(availableCollections, map) : availableCollections;

  // _renderArgs.property tells us what collection property to use for the value.
  const valueField = (schema._rendererArgs?.property || "ID") as keyof ICollection;
  const filter =
    schema._rendererArgs?.filter?.operator == "notEmpty"
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (c: any) => schema._rendererArgs?.filter?.field && notEmpty(c[schema._rendererArgs?.filter?.field])
      : () => true;
  const options: EnumOption[] = collections.filter(filter).map((c) => ({ label: c.name, value: c[valueField] }));

  // If type is array, we'll make this a multi-select.
  return <MaterialOneOfEnumControl {...{ ...props, options: options, multiple: schema.type == "array" }} />;
};

const collectionSelectorControlTester: RankedTester = rankWith(
  6,
  schemaMatches((s) => {
    return (s as ExtendedSchema)._renderer == "Collection";
  })
);

/**
 * A component to select a data column from a collection. Until a `collectionID` is selected somewhere else in the form,
 * this component will have no options.
 *
 * _rendererArgs may include `property` as either 'properties' or 'aggregates'. If not present, values are pulled
 * from `properties`.
 */
const CollectionColumnSelectorControl = (props: ControlProps & OwnPropsOfEnum & WithOptionLabel & TranslateProps) => {
  const schema = resolveSchema(props.schema, props.uischema.scope, props.schema) as ExtendedSchema;
  const { availableCollections } = useAppSelector((state) => state.pages.truterritory);

  const propertiesField =
    schema._rendererArgs?.property === "properties" || schema._rendererArgs?.property === "aggregates"
      ? schema._rendererArgs?.property
      : "properties";

  // If there's a collectionID value set, grab that collection.
  const data = useJsonForms().core?.data;
  const options: EnumOption[] =
    (data.collectionID &&
      availableCollections
        .find((c) => c.ID === data.collectionID)
        ?.[propertiesField]?.filter((prop) => !prop.system && !prop.hidden)
        .map((prop) => ({ label: prop.name, value: prop.column }))) ||
    [];
  return <MaterialOneOfEnumControl {...{ ...props, options: options, multiple: schema.type == "array" }} />;
};

const collectionColumnSelectorControlTester: RankedTester = rankWith(
  6,
  schemaMatches((s) => {
    return (s as ExtendedSchema)._renderer == "CollectionColumn";
  })
);

/**
 * Following is the component to render a single variable config and all its supporting functions.
 */
interface IVariableComponentProps {
  variable: ITemplateVariable;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: { [key: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: FormikHelpers<any>["setFieldValue"];
  errors: string[];
}

function prepareUiSchema(source: IDataSource | undefined): Layout {
  if (!source) {
    return {
      type: "VerticalLayout",
      elements: [],
    };
  }

  // Make an array of (sub)schemas.
  const schemas = source.options && "allOf" in source.options ? source.options.allOf : [source.options];

  // Make UI Schema entries for all the properties which will always display.
  const regularProperties = schemas?.flatMap((s) =>
    Object.keys(s?.properties || {}).map((k) => makeUiSchemaProperty(k, s?.properties && s.properties[k]))
  );

  // Now do the same for any conditional properties.
  const conditionalProperties = schemas?.flatMap((s) => makeConditionalUiSchemaProperties(s)).filter((p) => !!p);

  // Put them all together into one UI schema.
  return {
    type: "VerticalLayout",
    elements: [...(regularProperties || []), ...(conditionalProperties || [])],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function makeUiSchemaProperty(key: string, val?: JsonSchema7, rule?: any): ControlElement {
  return {
    type: "Control",
    scope: `#/properties/${key}`,
    // For now, always turn off autocomplete so that regular Select elements are preferred for enums.
    options: { autocomplete: false },
    rule,
  };
}

function makeConditionalUiSchemaProperties(schema?: JsonSchema7): ControlElement[] {
  if (!schema || !schema.if || !schema.then || !schema.then.properties) return [];

  // Make a UI Schema "rule" from the JSON Schema "if".
  const rule = {
    effect: "SHOW",
    condition: {
      scope: "#",
      schema: schema.if,
    },
  };

  return Object.keys(schema.then.properties).map((key) => makeUiSchemaProperty(key, undefined, rule));
}

const VariableComponent: React.FC<JSX.IntrinsicAttributes & IVariableComponentProps> = ({
  variable,
  value,
  setFieldValue,
  errors,
}: IVariableComponentProps) => {
  const [touched, setTouched] = useState(false);
  const { data: sources } = useGetDataSourcesQuery(variable.type);
  const chosenSource = sources?.find((s) => s.id == value.dataSourceID);
  const uischema = prepareUiSchema(chosenSource);

  const renderers = [
    ...materialRenderers,
    { tester: collectionSelectorControlTester, renderer: CollectionSelectorControl },
    { tester: collectionColumnSelectorControlTester, renderer: CollectionColumnSelectorControl },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onChange(e: any) {
    const { data } = e;
    // Copy everything present in data into value.
    for (const i in data) {
      if (value[i] != data[i]) {
        setFieldValue(i, data[i]);
        setTouched(true);
      }
    }

    // Unset anything not present in data.
    for (const i in value) {
      if (!(i in data)) {
        setFieldValue(i, undefined);
        setTouched(true);
      }
    }
  }

  // Set dataSourceID and wipe out previous settings for this variable.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function setDatasource(e: any) {
    setFieldValue("", {});
    setFieldValue("dataSourceID", e.target.value || undefined);
  }

  return (
    <tr>
      <td title={variable.name}>
        <span title={JSON.stringify(variable, null, 2)}>{variable.description}</span>
        <InputLabel error={false}>{variable.longDescription}</InputLabel>
      </td>
      <td>
        <div className="select-holder form-control">
          <Field
            as="select"
            name={`values.${variable.name}.dataSourceID`}
            onChange={setDatasource}
            title={variable.type}
          >
            <option value="">Choose datasource...</option>
            {sources?.map((source) => (
              <option key={source.id} value={source.id} title={source.description}>
                {source.name}
              </option>
            ))}
          </Field>
        </div>
        {errors.map((error, i) => (
          <div key={i} className="text-danger" style={{ clear: "both" }}>
            {error}
          </div>
        ))}
      </td>
      <td>
        {chosenSource && (
          <JsonForms
            key={chosenSource.id}
            schema={{ ...chosenSource.options }}
            uischema={uischema}
            data={{
              ...value,
              selectionLayerNames:
                "selectionLayerNames" in value && Array.isArray(value.selectionLayerNames)
                  ? value.selectionLayerNames
                  : [],
            }}
            renderers={renderers}
            cells={materialCells}
            onChange={onChange}
            validationMode={touched ? "ValidateAndShow" : "NoValidation"}
          />
        )}
      </td>
    </tr>
  );
};

const ReportsComponent: React.FC<JSX.IntrinsicAttributes> = () => {
  const { map, availableCollections } = useAppSelector((state) => state.pages.truterritory);
  const { data: templates } = useGetReportTemplatesQuery();
  const { data: reports } = useGetReportsQuery(map?.ID || skipToken);

  const [editingReport, setEditingReport] = useState<IReport | null>(null);
  const [showReportModal, setShowReportModal] = useState(false);

  const [createReport, creationResult] = useCreateReportMutation();
  const [updateReport, updateResult] = useUpdateReportMutation();
  const [deleteReport] = useDeleteReportMutation();

  // Translate creation/update results into error messages per field.
  const [apiErrorMsg, apiErrors] = useMemo((): [string, { [key: string]: string[] }] => {
    const saveError = creationResult.error || updateResult.error;

    if (!saveError) {
      return ["", {}];
    }

    if (!("status" in saveError)) {
      return [saveError.message || "", {}];
    }

    if (typeof saveError.data == "object" && saveError.data !== null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = saveError.data;
      return [data.status.message || "", data.meta.errors || {}];
    }

    return ["", {}];
  }, [creationResult, updateResult]);

  if (!map) return <></>;

  const theme = createTheme({
    palette: {
      primary: {
        light: "#4184b6",
        main: "#0b80d0",
        dark: "#283239",
        contrastText: "#fff",
      },
      // secondary: {},
    },
    components: {
      MuiSelect: {
        defaultProps: {
          // Need a z-index higher than the modal's.
          MenuProps: { style: { zIndex: 10000 } },
        },
      },
    },
  });

  const valueToNumber = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get(target: any, prop: string) {
      return prop == "value" ? parseInt(target[prop]) : target[prop];
    },
  };

  function collectionName(ID: number) {
    return availableCollections?.find((c) => c.ID === ID)?.name;
  }

  function closeReportModal() {
    setEditingReport(null);
    setShowReportModal(false);
  }

  function openReportModal(report?: IReport) {
    setEditingReport(report || null);
    setShowReportModal(true);
  }

  // Validation in Formik checks everything down to the dataSourceID for required variables.
  // Below that, JSON Forms does its own validation for each datasource config.
  // I haven't yet figured out how to trigger validation synchronously in all the JSON Form instances
  // or how to retrieve the error messages from all of them after validation, so we don't currently
  // stop Formik submission for any errors in JSON Forms. Instead, we rely on the API to catch those.
  //
  // Also, we're not using Yup for validation because I couldn't get it to stop breaking when trying to
  // retrieve validation rules for fields I don't want it to validate (datasource config).
  // (Josh)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function validate(values: any) {
    const errors: { [key: string]: string } = {};

    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.templateID) {
      errors.templateID = "Required";
    }

    const template = templates?.find((t) => t.ID === values.templateID);
    if (template) {
      for (const variable of template.variables) {
        const field = `values.${variable.name}.dataSourceID`;
        if (!variable.nullable && !values.values[variable.name].dataSourceID) {
          errors[field] = "Required";
        }
      }
    }

    return errors;
  }

  const collections = getCollections(availableCollections, map);

  function reportAreaPhrase(report: IReport): string {
    return (
      (report.areaType == ReportAreaType.MultiFeature && "one or more features") ||
      (report.areaType == ReportAreaType.Isochrone && "an " + report.areaType) ||
      "a " + report.areaType
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div id="map-reports-module">
          <h4>Reports</h4>
          {(reports && (
            <table className="striped-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Template</th>
                  <th>Scope</th>
                  <th>Created</th>
                  <th>Updated</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {[...reports]
                  .sort((a, b) => (a.ID || 0) - (b.ID || 0))
                  .map((report) => (
                    <tr key={report.ID}>
                      <td title={JSON.stringify(report, null, 2)}>{report.ID}</td>
                      <td title={JSON.stringify(report, null, 2)}>{report.name}</td>
                      <td>{report.templateID}</td>
                      <td>
                        Reporting on <b>{reportAreaPhrase(report)}</b>{" "}
                        {(report.collectionScope?.length && (
                          <span>
                            when interacting with{" "}
                            <b>
                              {oxfordConcat(
                                report.collectionScope.map((id) => collectionName(id) || "<unknown>"),
                                false,
                                "or"
                              )}
                            </b>
                          </span>
                        )) ||
                          "anywhere"}
                        .
                      </td>
                      <td>{report.createdAt}</td>
                      <td>{report.updatedAt}</td>
                      <td>
                        <Button className="edit" onClick={openReportModal.bind(null, report)} />
                      </td>
                      <td className="report-field-delete">
                        <ConfirmButton
                          type="del"
                          text={`Are you sure you want to delete the report, "${report.name}"?`}
                          yes={"Delete"}
                          callback={deleteReport.bind(null, report)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )) ||
            "<p>This map has no reports</p>"}

          <Button className="add-full light" onClick={() => openReportModal()}>
            Add Report
          </Button>

          <h4>Old Reports</h4>
          <p>This map has {map?.reports?.length || 0} old/deprecated map reports.</p>
          {!!map?.reports?.length && (
            <table className="striped-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Enabled</th>
                  <th>Template ID</th>
                  <th>Collection ID</th>
                  <th>Require Geometry</th>
                  <th>Accepts Selection</th>
                  <th>Created</th>
                  <th>Updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {map?.reports?.map((report) => (
                  <tr key={report.ID}>
                    <td>{report.ID}</td>
                    <td>{report.label}</td>
                    <td>{report.enabled ? "yes" : "no"}</td>
                    <td>{report.templateID}</td>
                    <td>{report.collectionID}</td>
                    <td>{report.requireGeometry ? "yes" : "no"}</td>
                    <td>{report.acceptsSelection ? "yes" : "no"}</td>
                    <td>{report.createdAt}</td>
                    <td>{report.updatedAt}</td>
                    <td>
                      <a className="icon-delete-2"></a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <Modal isOpen={showReportModal} onClose={closeReportModal} id="truterritory-save-report-modal">
          <Formik
            enableReinitialize
            validate={validate}
            initialValues={{
              name: editingReport?.name || "",
              collectionScope: editingReport?.collectionScope,
              interactionScope: editingReport?.interactionScope,
              areaType: editingReport?.areaType,
              templateID: editingReport?.templateID,
              values: editingReport?.values,
            }}
            onSubmit={async (values) => {
              if (editingReport) {
                await updateReport({
                  ID: editingReport.ID,
                  mapID: map.ID,
                  templateID: values.templateID || editingReport.templateID,
                  name: values.name,
                  collectionScope: values.collectionScope === undefined ? [] : values.collectionScope,
                  interactionScope: values.interactionScope === undefined ? [] : values.interactionScope,
                  areaType: values.areaType || ReportAreaType.Point,
                  values: values.values === undefined ? [] : values.values,
                  createdAt: editingReport.createdAt,
                }).unwrap();
              } else {
                await createReport({
                  mapID: map.ID,
                  templateID: values.templateID || "",
                  name: values.name || "",
                  collectionScope: values.collectionScope || [],
                  interactionScope: values.interactionScope || [],
                  areaType: values.areaType || ReportAreaType.Point,
                  values: values.values || {},
                  createdAt: "",
                }).unwrap();
              }

              closeReportModal();
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
              <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="modal-header col-md-12" title={JSON.stringify(values, null, 2)}>
                  <h4>{editingReport?.ID ? "Edit" : "Create"} Report</h4>
                </div>

                <div className="modal-body col-md-12">
                  {apiErrorMsg && <p className="text-danger">{apiErrorMsg}</p>}
                  <div className="form-group">
                    <label className="control-label col-md-3" htmlFor="report-name">
                      Name
                    </label>
                    <div className="col-md-6">
                      <Field type="text" name="name" id="report-name" />
                      {touched.name && errors.name && <div className="text-danger">{errors.name}</div>}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-3">What to report on:</label>
                    <div className="col-md-9">
                      <div>
                        <Field type="radio" name="areaType" id="area-type-point" value="point" />
                        <label htmlFor="area-type-point">A point (lat/lon)</label>
                      </div>
                      {/* <div>
                        <Field type="radio" name="areaType" id="area-type-circle" value="circle" />
                        <label htmlFor="area-type-circle">A circle or radius</label>
                      </div> */}
                      {/* <div>
                        <Field type="radio" name="areaType" id="area-type-isochrone" value="isochrone" />
                        <label htmlFor="area-type-isochrone">A drivetime area or isochrone</label>
                      </div> */}
                      <div>
                        <Field type="radio" name="areaType" id="area-type-feature" value="feature" />
                        <label htmlFor="area-type-feature">A single feature on the map</label>
                      </div>
                      <div>
                        <Field type="radio" name="areaType" id="area-type-multi-feature" value="multi-feature" />
                        <label htmlFor="area-type-multi-feature">
                          Multiple features on the map (requires selection)
                        </label>
                      </div>
                      {touched.areaType && errors.areaType && <div className="text-danger">{errors.areaType}</div>}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-3">Enabled only when interacting with:</label>
                    <div className="col-md-9">
                      {collections.map((collection) => (
                        <div key={collection.ID} title={`${collection.ID}`}>
                          <Field
                            type="checkbox"
                            name="collectionScope"
                            id={`collectionScope${collection.ID}`}
                            value={collection.ID}
                            className={classNameMapper({ _checked: !!values.collectionScope?.includes(collection.ID) })}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(e: any) => handleChange({ ...e, target: new Proxy(e.target, valueToNumber) })}
                          />
                          <label className="checkbox" htmlFor={`collectionScope${collection.ID}`}>
                            {collection.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-3">Template</label>
                    <div className="select-holder col-md-6 form-control">
                      <Field as="select" name="templateID">
                        <option value="">Choose template...</option>
                        {templates?.map((template) => (
                          <option key={template.ID} value={template.ID} title={template.description}>
                            {template.summary}
                          </option>
                        ))}
                      </Field>
                      {touched.templateID && errors.templateID && (
                        <div className="text-danger">{errors.templateID}</div>
                      )}
                    </div>
                  </div>

                  <div>
                    <h3>Variables</h3>
                    <table className="striped-table" style={{ width: "95%" }}>
                      <thead>
                        <tr>
                          <th>Variable</th>
                          <th>Data Source</th>
                          <th>Configuration</th>
                        </tr>
                      </thead>
                      <tbody>
                        {templates
                          ?.find((t) => t.ID == values.templateID)
                          ?.variables.map((variable) => (
                            <VariableComponent
                              key={variable.name}
                              variable={variable}
                              value={
                                (values.values && variable.name in values.values && values.values[variable.name]) || {}
                              }
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              setFieldValue={(field: string, value: any) =>
                                setFieldValue(
                                  field ? `values.${variable.name}.${field}` : `values.${variable.name}`,
                                  value,
                                  false
                                )
                              }
                              errors={
                                [
                                  errors[`values.${variable.name}.dataSourceID` as keyof typeof errors],
                                  ...(apiErrors[variable.name] || []),
                                ].filter((e) => !!e) as string[]
                              }
                            />
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="modal-footer">
                  <div className="controls">
                    {isSubmitting ? (
                      <Button type="submit" variant="light-blue" disabled>
                        Saving...
                      </Button>
                    ) : (
                      <Button type="submit" variant="light-blue">
                        Save
                      </Button>
                    )}
                    <Button variant="plain" onClick={closeReportModal}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
      </ThemeProvider>
    </>
  );
};

export const TruTerritoryReports = withReduxProvider(ReportsComponent);
